export const injuredPlayerMetricKeys = [
  'prior_snap_rate_pass',
  'prior_target_share',
  'prior_carry_share',
  'injury_status',
]
export const healthyPlayerMetricKeys = [
  'projected_snap_rate_pass',
  'prior_snap_rate_pass',
  'diff_snap_rate_pass',
  'projected_snap_rate_run',
  'prior_snap_rate_run',
  'diff_snap_rate_run',
  'projected_targets',
  'prior_targets',
  'diff_targets',
  'projected_receptions',
  'prior_receptions',
  'diff_receptions',
  'projected_air_yards',
  'prior_air_yards',
  'diff_air_yards',
  'projected_rec_td',
  'prior_rec_td',
  'diff_rec_td',
  'projected_rec_yards',
  'prior_rec_yards',
  'diff_rec_yards',
  'projected_carries',
  'prior_carries',
  'diff_carries',
  'projected_rush_yds',
  'prior_rush_yds',
  'diff_rush_yds',
  'projected_rush_td',
  'prior_rush_td',
  'diff_rush_td',
]

export const wrCbMetricKeys = [
  'snaps_against_WR_CB_career',
  'yards_caught_WR_CB_career',
  'targets_WR_CB_career',
  'yards_per_route_WR_CB_career',
  'target_rate_WR_CB_career',
]

export const validExternalMetricKeys = [...injuredPlayerMetricKeys, ...healthyPlayerMetricKeys]

export const validTwoPlayerMetricKeys = [...wrCbMetricKeys]
