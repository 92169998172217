import { AddListenerOverloads } from '@reduxjs/toolkit/dist/listenerMiddleware/types'
import { isAnyOf } from '@reduxjs/toolkit'
import { FantasyMatchupPosition, UpdatedTimeVariant } from '@pff-consumer/schema'
import { consumerApi } from '../../lib/consumer-api/consumer-api'
import { fantasySlice } from '../../lib/fantasy/fantasy.slice'
import type { RootState } from '../store.fantasy'
import { fantasyLdaMdsApi } from '../../lib/fantasy-lda-mds/fantasy-lda-mds-api'

export const addFantasyMatchupsListeners = (startListening: AddListenerOverloads<unknown>) => {
  startListening({
    matcher: isAnyOf(
      fantasySlice.actions.updateMatchupFilterRunningBackType.match,
      fantasySlice.actions.updateMatchupFilterPosition.match,
      consumerApi.endpoints.getSDKMatchups.matchFulfilled,
      fantasyLdaMdsApi.endpoints.getUpdatedTime.matchFulfilled
    ),
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as RootState
      listenerApi.cancelActiveListeners()
      const { position, runningBackType } = state.fantasy.matchups.filters
      const isRbMatchupSelected = position === FantasyMatchupPosition.RB
      const { data, status } = consumerApi.endpoints.getSDKMatchups.select({ position, runningBackType })(state)
      if (status === 'fulfilled') {
        listenerApi.dispatch(fantasySlice.actions.updateFantasyMatchups({ matchups: data.matchups }))
        listenerApi.dispatch(
          fantasySlice.actions.updateFantasyEventWeek({
            abbreviation: data.currentEventWeek.abbreviation,
            status: data.currentEventWeek.status,
          })
        )
      }

      const payload = isRbMatchupSelected
        ? {
            variant: UpdatedTimeVariant.FANTASY_MATCHUPS,
            matchupPosition: position,
            matchupRunningBackType: runningBackType,
          }
        : {
            variant: UpdatedTimeVariant.FANTASY_MATCHUPS,
            matchupPosition: position,
          }

      const { data: updatedTimeData, status: updatedTimeStatus } =
        fantasyLdaMdsApi.endpoints.getUpdatedTime.select(payload)(state)
      if (updatedTimeStatus === 'fulfilled') {
        listenerApi.dispatch(fantasySlice.actions.updateFantasyMatchupsUpdatedTime(updatedTimeData.lastUpdatedAt))
      }
    },
  })

  startListening({
    matcher: isAnyOf(consumerApi.endpoints.getPlayerInjuryStatusFantasy.matchFulfilled),
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(fantasySlice.actions.updateFantasyPlayersInjuryRecord(action.payload))
    },
  })
}
