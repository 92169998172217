export type ConnectAnalyticsClickTypes =
  | 'copy to clipboard button'
  | 'analysis text'
  | 'back to game list'
  | 'game card'
  | 'week filter'
  | 'week changed'

export interface ConnectScreenInfo {
  experience: string
  key_insight_tab: string
  week: string
  premium: boolean
}

export enum ConnectScreens {
  GAME_VIEW = 'Game View',
  PLAYER_VIEW = 'Player View',
  TEAM_VIEW = 'Team View',
  USAGE_VIEW = 'PFF Live View',
  TEAM_INSIGHTS_VIEW = 'Team Insights View',
  KEY_INSIGHTS = 'Key Insights Hub Page',
}

export interface ConnectUserGroupInfo {
  user_type?: string
  user_domain?: string
}

export interface ConnectFilterChangeInfo extends ConnectScreenInfo {
  key_insight_filter: string
}

export interface AnalysisTextSelectedInfo {
  type: ConnectAnalyticsClickTypes
  event_id?: number
  player_id?: number
  week?: string
  home_team?: string
  away_team?: string
  position?: number
  analysis_text?: string
  connect_view?: ConnectScreens
}

export interface ConnectPlayerSearchEventInfo extends ConnectScreenInfo {
  search_term: string
  player_id?: number
}

export const CONNECT_ANALYTICS_EVENTS = {
  DOUBLE_CLICK: 'Double Click',
  FILTER_CHANGE: 'Filter Change',
  CLICK: 'Click',
  VIEW: 'Key Insights View',
} as const

export type ConnectPlayerSearchEvents = 'Search Field Activated' | 'Search Completed' | 'Search Incomplete'

export type ConnectAnalyticsEvents =
  | ConnectPlayerSearchEvents
  | (typeof CONNECT_ANALYTICS_EVENTS)[keyof typeof CONNECT_ANALYTICS_EVENTS]

export type ConnectAnalyticsEventProperties = ConnectScreenInfo & AnalysisTextSelectedInfo

export type ConnectAnalyticsEventArgs =
  | [eventName: 'Double Click', eventProperties: AnalysisTextSelectedInfo]
  | [eventName: 'Click', eventProperties: AnalysisTextSelectedInfo]
  | [eventName: 'Filter Change', eventProperties: ConnectFilterChangeInfo]
  | [eventName: 'Search Field Activated']
  | [eventName: 'Search Completed', eventProperties: ConnectPlayerSearchEventInfo]
  | [eventName: 'Search Incomplete', eventProperties: ConnectPlayerSearchEventInfo]
  | [eventName: 'Key Insights View', eventProperties: ConnectScreenInfo]
