import { FantasySort } from '@pff-consumer/schema'
import { Matchup } from '@pff-consumer/schema/sdk/matchups'
import { sorters } from '@pff-consumer/utils'
import { orderBy as lodashOrderBy } from 'lodash'

const MATCHUP_GRADE_GOOD = 3

const getOverallGrade = (overallScore: number) => {
  // assign grades 'C' -> GREAT, 'B' -> GOOD, 'A' -> POOR,  to matchups for sorting purpose
  if (overallScore > MATCHUP_GRADE_GOOD) return 'C'
  if (overallScore < MATCHUP_GRADE_GOOD) return 'A'
  return 'B'
}

export const getSortedMatchups = (
  matchupsList: Matchup[],
  overallScoreSortBy: FantasySort,
  overallScoreOrderBy: boolean | 'asc' | 'desc'
) => {
  const matchupsListWithGrade = matchupsList.map((matchup) => {
    const newMatchup = { ...matchup }
    newMatchup.overallGrade = getOverallGrade(newMatchup.overallScore)
    return newMatchup
  })

  const sortedMatchups = lodashOrderBy(
    matchupsListWithGrade.slice(),
    [sorters[overallScoreSortBy], sorters[FantasySort.SORT_BY_MATCHUP_RANK]],
    [overallScoreOrderBy, 'asc']
  ) as Matchup[]

  return sortedMatchups
}
