export interface TeamInfo {
  abbreviation: string
  nickname: string
}

export enum OffenseMetricPosition {
  TeamOffense = 'team-offense',
  Quarterback = 'quarterback',
  TightEnd = 'tight-end',
  WideReceiver = 'wide-receiver',
  RunningBack = 'running-back',
  OffensiveLine = 'ol',
}

export enum TwoPlayerMetricPositions {
  WR_CB = 'wr_cb',
}

export enum DefenseMetricPosition {
  DefensiveLine = 'dl',
  TeamDefense = 'team-defense',
  Cornerback = 'cornerback',
}

export enum OffensePlayerPosition {
  Quarterback = 'quarterback',
  TightEnd = 'tight-end',
  WideReceiver = 'wide-receiver',
  RunningBack = 'running-back',
  OffensiveLine = 'ol',
}

export enum DefensePlayerPosition {
  Cornerback = 'cornerback',
  DefensiveLine = 'dl',
}

export enum MetricPosition {
  Quarterback = 'quarterback',
  TightEnd = 'tight-end',
  WideReceiver = 'wide-receiver',
  RunningBack = 'running-back',
  DefensiveLine = 'dl',
  OffensiveLine = 'ol',
  TeamDefense = 'team-defense',
  TeamOffense = 'team-offense',
  Cornerback = 'cornerback',
}

export const injuryMetricPrefix = 'injured_player_metric_'

export const InjuredPlayerPositionAbbreviation = <const>{
  WR: MetricPosition.WideReceiver,
  RB: MetricPosition.RunningBack,
  HB: MetricPosition.RunningBack,
  FB: MetricPosition.RunningBack,
  TE: MetricPosition.TightEnd,
  QB: MetricPosition.Quarterback,
  CB: MetricPosition.Cornerback,
}

export const InjuredPlayerPosition = <const>{
  [MetricPosition.WideReceiver]: 'WR',
  [MetricPosition.RunningBack]: 'RB',
  [MetricPosition.TightEnd]: 'TE',
  [MetricPosition.Quarterback]: 'QB',
  [MetricPosition.Cornerback]: 'CB',
}

export interface PlayerMetric {
  team_abbreviation: string
  team_name: string
  player_id: number
  player: string
  position: string
}

export interface HealthyPlayerMetric extends InjuredPlayer {
  injured_player_id: number
  projected_snap_rate_pass: number
  prior_snap_rate_pass: number
  diff_snap_rate_pass: number
  projected_snap_rate_run: number
  prior_snap_rate_run: number
  diff_snap_rate_run: number
  projected_targets: number
  prior_targets: number
  diff_targets: number
  projected_receptions: number
  prior_receptions: number
  diff_receptions: number
  projected_air_yards: number
  prior_air_yards: number
  diff_air_yards: number
  projected_rec_td: number
  prior_rec_td: number
  diff_rec_td: number
  projected_rec_yards: number
  prior_rec_yards: number
  diff_rec_yards: number
  projected_carries: number
  prior_carries: number
  diff_carries: number
  projected_rush_yds: number
  prior_rush_yds: number
  diff_rush_yds: number
  projected_rush_td: number
  prior_rush_td: number
  diff_rush_td: number
}

export interface InjuredPlayer extends PlayerMetric {
  season: number
  week: number
}

export interface InjuredPlayerMetric extends InjuredPlayer {
  injury_status: string
  franchise_id: number
  last_played_week: number
  prior_snap_rate_pass: number
  prior_target_share: number
  prior_carry_share: number
  replacementMetrics: Partial<HealthyPlayerMetric>[]
}

export interface InjuredPlayerNarrativeMetric extends PlayerMetric {
  injured_player_id: number
  injured_player: string
  injured_player_position: string
  injured_player_last_played_week: number
}

export interface TeamDefensiveMetric {
  defense_abbreviation: string
  defense_name: string
  position: string
}

export interface TeamOffensiveMetric {
  offense_abbreviation: string
  offense_name: string
  position: string
}

export interface WrCbCareerMatchupMetric {
  game_id: number
  player_id_WR: number
  player_id_CB: number
  franchise_id_WR: number
  player_WR: string
  player_CB: string
  position: string
  snaps_against_WR_CB_career?: string
  yards_caught_WR_CB_career?: string
  targets_WR_CB_career?: string
  yards_per_route_WR_CB_career?: string
  target_rate_WR_CB_career?: string
  team_abbreviation?: string
  team_name?: string
}

export interface WideReceiverMetric extends PlayerMetric, Record<string, any> {}

export interface QuarterbackMetric extends PlayerMetric, Record<string, any> {}

export interface CornerbackMetric extends PlayerMetric, Record<string, any> {}

export interface TightEndMetric extends PlayerMetric, Record<string, any> {}

export interface RunningBackMetric extends PlayerMetric, Record<string, any> {}

export interface PlayersMetric extends PlayerMetric, Record<string, any> {}

export interface WrCbCareerMatchupsMetric extends PlayerMetric, Record<string, any> {}

export interface InjuredPlayerImpactMetrics extends InjuredPlayerMetric, Record<string, any> {}

export interface InjuredPlayerNarrativeMetrics extends InjuredPlayerNarrativeMetric, Record<string, any> {}

export interface HealthyPlayerMetrics extends HealthyPlayerMetric, Record<string, any> {}

export interface TeamDefenseMetric extends TeamDefensiveMetric, Record<string, any> {}

export interface TeamOffenseMetric extends TeamOffensiveMetric, Record<string, any> {}

export interface DefensiveLineMetric extends PlayerMetric {
  season: string
  pass_rush_grade_over_expectation: string
  percentile_pass_rush_grade_over_expectation: string
  franchise_id: string
}

export interface OffensiveLineMetric extends PlayerMetric {
  season: string
  pass_block_grade_over_expectation: string
  percentile_pass_block_grade_over_expectation: string
  franchise_id: string
}

export type TwoPlayerMetrics = WrCbCareerMatchupMetric // | add other two player metrics here as needed

export type MetricColumn =
  | PlayerMetric
  | InjuredPlayerNarrativeMetrics
  | TeamDefensiveMetric
  | TeamOffensiveMetric
  | WrCbCareerMatchupMetric

export type MetricColumnWithMetrics =
  | InjuredPlayerImpactMetrics
  | PlayersMetric
  | HealthyPlayerMetrics
  | TeamDefenseMetric
  | TeamOffenseMetric
  | WrCbCareerMatchupsMetric
