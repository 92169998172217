import { FANTASY_CONSTANTS } from '@pff-consumer/schema'
import { formatUpdatedTime } from '../format/format-date'
import { auctionValuePlayerIdMap } from './hard-coded-data/auction-value-player-map'
import { getRankingsPlayersPosition } from './get-rankings-players-positions'

export const transformRankingsResponse = (response: any) => {
  // TODO: FIX TYPE
  // @ts-ignore

  const rankings = Array.isArray(response.rankings) ? response.rankings : []

  try {
    const transformed = rankings.map((entry: any) => {
      // TODO remove once we support league types better

      return {
        adpTrend: entry.adpTrend,
        adp: entry.adp,
        adpPosition: entry.adpPosition,
        adpScoringType: entry.adpScoringType,
        adpSource: (entry.adpSource || '').toLowerCase(),
        bye: entry.byeWeek,
        // TODO: Strop stripping dummy text once we have full rankings
        expertAnalysis: entry.expertAnalysis?.filter((analysis: string) => !analysis.includes('piece of information')),
        rank: {
          current: entry.rank?.current || 'NR',
          // TODO: Check if we can remove high/low
          high: 'NR',
          low: 'NR',
          position: entry.rank?.position || null,
          rankerName: entry.rank?.rankerName || null,
        },
        projection: entry.projection,
        tags: entry.tags,
        tier: entry.tier,
        dob: entry.birthDate,
        first_name: entry.firstName,
        last_name: entry.lastName,
        jersey_number: entry.jerseyNumber,
        id: entry.playerId,
        position: getRankingsPlayersPosition(entry.position),
        height: entry.height,
        weight: entry.weight,
        // @ts-ignore
        team: FANTASY_CONSTANTS.TEAMS[entry.teamId],
        teamCity: entry.teamCity,
        teamName: entry.teamName,
        auctionValue: auctionValuePlayerIdMap[entry.playerId] || 0,
      }
    })

    return { players: transformed, lastUpdatedAt: formatUpdatedTime(response.lastUpdatedAt) }
  } catch (e) {
    console.error(e)
    console.log('error', e)
    return { players: [], lastUpdatedAt: null }
  }
}
