import { Matchup } from '@pff-consumer/schema/sdk/matchups'
import { getOverallGrade } from '@pff-consumer/utils'

export const addOverallGradeToMatchups = (matchups: Matchup[]) => {
  return matchups.map((matchup) => {
    const newMatchup = { ...matchup }
    newMatchup.overallGrade = getOverallGrade(newMatchup.overallScore)
    return newMatchup
  })
}
