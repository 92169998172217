import { FantasySort } from '@pff-consumer/schema'
import { sortByRank } from './sortByRank'
import { sortAdp } from './sortAdp'
import { sortProjection } from './sortProjection'
import { sortPickGrade } from './sortPickGrade'
import { sortNextRoundAvailability } from './sortNextRoundAvailability'
import { sortAuctionValue } from './sortAuctionValue'
import { sortByMatchupGrade } from './sortByMatchupGrade'
import { sortByMatchupRank } from './sortByMatchupRank'

export const sorters = {
  [FantasySort.SORT_BY_RANK]: sortByRank,
  [FantasySort.SORT_BY_ADP]: sortAdp,
  [FantasySort.SORT_BY_PROJ]: sortProjection,
  [FantasySort.SORT_BY_PICK_GRADE]: sortPickGrade,
  [FantasySort.SORT_BY_AVAILABILITY]: sortNextRoundAvailability,
  [FantasySort.SORT_BY_AUCTION_VALUE]: sortAuctionValue,
  [FantasySort.SORT_BY_POS_RANK]: sortByRank,
  [FantasySort.SORT_BY_MATCHUP_GRADE]: sortByMatchupGrade,
  [FantasySort.SORT_BY_MATCHUP_RANK]: sortByMatchupRank,
}
