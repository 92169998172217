import { Sample } from './game-script-dto-types'
import { MetricColumn, MetricColumnWithMetrics } from './metric-broker'

export interface Situation {
  templateId: string
  summary: string
  samples: Sample[]
  data: MetricColumn[]
  rank?: number
  displayOrder?: number
}

export interface GameScriptSituation {
  gameId: number
  situations: Situation[]
}

export interface SituationDTO {
  templateId: string
  summary: string
  samples: Sample[]
  data: MetricColumnWithMetrics[]
  rank: number
  displayOrder: number
}

export interface GameScriptSituationDTO {
  gameId: number
  situations: SituationDTO[]
}

export enum GameScriptMetricType {
  PlayerMetric = 'playerMetric',
  InjuredPlayerMetric = 'injuredPlayerMetric',
  TwoPlayerMetric = 'twoPlayerMetric',
}
