export enum WeekGroup {
  PRE = 'PRE',
  REG = 'REG',
  POST = 'PO',
}

export function isValidWeekGroup(weekGroup: string | WeekGroup): weekGroup is WeekGroup {
  return Object.values(WeekGroup).includes(weekGroup as WeekGroup)
}

export enum WeekGroupValues {
  PRE = '-5,-4,-3,-2,-1',
  REG = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18',
  POST = '28,29,30,32',
}

export function isValidWeekGroupValues(weekGroupValues: string | WeekGroupValues): weekGroupValues is WeekGroupValues {
  return Object.values(WeekGroupValues).includes(weekGroupValues as WeekGroupValues)
}

export const weekGroupMap: Record<WeekGroup, WeekGroupValues> = {
  [WeekGroup.PRE]: WeekGroupValues.PRE,
  [WeekGroup.REG]: WeekGroupValues.REG,
  [WeekGroup.POST]: WeekGroupValues.POST,
}

export interface PremiumStatsPreviewKeyStatsParams {
  playerId: number
  league: string
  season: string
  weekGroup: WeekGroup
}

export const GRADE_AVERAGE_PRECISION = 10

export enum PreviewTypes {
  QB_PASSING_PREVIEW = 'QB_PASSING_PREVIEW',
  QB_RUSHING_PREVIEW = 'QB_RUSHING_PREVIEW',
  RB_RUSHING_PREVIEW = 'RB_RUSHING_PREVIEW',
  RB_RECEIVING_PREVIEW = 'RB_RECEIVING_PREVIEW',
  FB_RUSHING_PREVIEW = 'FB_RUSHING_PREVIEW',
  FB_RECEIVING_PREVIEW = 'FB_RECEIVING_PREVIEW',
  WR_RECEIVING_PREVIEW = 'WR_RECEIVING_PREVIEW',
  WR_RUSHING_PREVIEW = 'WR_RUSHING_PREVIEW',
  TE_RECEIVING_PREVIEW = 'TE_RECEIVING_PREVIEW',
  TE_RUSHING_PREVIEW = 'TE_RUSHING_PREVIEW',
  T_BLOCKING_PREVIEW = 'T_BLOCKING_PREVIEW',
  T_POSITIONAL_PIVOT_PREVIEW = 'T_POSITIONAL_PIVOT_PREVIEW',
  G_BLOCKING_PREVIEW = 'G_BLOCKING_PREVIEW',
  G_POSITIONAL_PIVOT_PREVIEW = 'G_POSITIONAL_PIVOT_PREVIEW',
  C_BLOCKING_PREVIEW = 'C_BLOCKING_PREVIEW',
  C_POSITIONAL_PIVOT_PREVIEW = 'C_POSITIONAL_PIVOT_PREVIEW',
  DI_DEFENSE_PREVIEW = 'DI_DEFENSE_PREVIEW',
  DI_SNAPS_PREVIEW = 'DI_SNAPS_PREVIEW',
  ED_DEFENSE_PREVIEW = 'ED_DEFENSE_PREVIEW',
  ED_SNAPS_PREVIEW = 'ED_SNAPS_PREVIEW',
  LB_DEFENSE_PREVIEW = 'LB_DEFENSE_PREVIEW',
  LB_SNAPS_PREVIEW = 'LB_SNAPS_PREVIEW',
  CB_DEFENSE_PREVIEW = 'CB_DEFENSE_PREVIEW',
  CB_SNAPS_PREVIEW = 'CB_SNAPS_PREVIEW',
  S_DEFENSE_PREVIEW = 'S_DEFENSE_PREVIEW',
  S_SNAPS_PREVIEW = 'S_SNAPS_PREVIEW',
  K_FIELD_GOALS_PREVIEW = 'K_FIELD_GOALS_PREVIEW',
  K_KICKING_PREVIEW = 'K_KICKING_PREVIEW',
  P_PUNTING_PREVIEW = 'P_PUNTING_PREVIEW',
  P_KICKING_PREVIEW = 'P_KICKING_PREVIEW',
}

export interface QbPassingPreview {
  previewType: PreviewTypes.QB_PASSING_PREVIEW
  stats: {
    dropbacks: number | null
    dropbacksPositionRank: number
    attempts: number | null
    attemptsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    interceptions: number | null
    interceptionsPositionRank: number
    avgDepthOfTarget: number | null
    avgDepthOfTargetPositionRank: number
    avgTimeToThrow: number | null
    avgTimeToThrowPositionRank: number
    pressureToSackRate: number | null
    pressureToSackRatePositionRank: number
    bigTimeThrows: number | null
    bigTimeThrowsPositionRank: number
    turnoverWorthyPlays: number | null
    turnoverWorthyPlaysPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offensive: number | null
    offensivePositionRank: number
    offensiveGradeAverage: number
    pass: number | null
    passPositionRank: number
    passGradeAverage: number
    positionRankTotal: number
  }
}

export interface QbRushingPreview {
  previewType: PreviewTypes.QB_RUSHING_PREVIEW
  stats: {
    attempts: number | null
    attemptsPositionRank: number
    designedYards: number | null
    designedYardsPositionRank: number
    runPlays: number | null
    runPlaysPositionRank: number
    scrambleYards: number | null
    scrambleYardsPositionRank: number
    scrambles: number | null
    scramblesPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface RbRushingPreview {
  previewType: PreviewTypes.RB_RUSHING_PREVIEW
  stats: {
    attempts: number | null // carries
    attemptsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    ypa: number | null
    ypaPositionRank: number
    fumbles: number | null
    fumblesPositionRank: number
    avoidedTackles: number | null
    avoidedTacklesPositionRank: number
    yardsAfterContact: number | null // deprecated / not currently used
    yardsAfterContactPositionRank: number // deprecated / not currently used
    yardsAfterContactPerAttempt: number | null
    yardsAfterContactPerAttemptPositionRank: number
    explosive: number | null
    explosivePositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface RbReceivingPreview {
  previewType: PreviewTypes.RB_RECEIVING_PREVIEW
  stats: {
    targets: number | null
    targetsPositionRank: number
    receptions: number | null
    receptionsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    drops: number | null
    dropsPositionRank: number
    avoidedTackles: number | null
    avoidedTacklesPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface FbRushingPreview {
  previewType: PreviewTypes.FB_RUSHING_PREVIEW
  stats: {
    attempts: number | null // carries
    attemptsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    ypa: number | null
    ypaPositionRank: number
    fumbles: number | null
    fumblesPositionRank: number
    avoidedTackles: number | null
    avoidedTacklesPositionRank: number
    yardsAfterContact: number | null // deprecated / not currently used
    yardsAfterContactPositionRank: number // deprecated / not currently used
    yardsAfterContactPerAttempt: number | null
    yardsAfterContactPerAttemptPositionRank: number
    explosive: number | null
    explosivePositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface FbReceivingPreview {
  previewType: PreviewTypes.FB_RECEIVING_PREVIEW
  stats: {
    targets: number | null
    targetsPositionRank: number
    receptions: number | null
    receptionsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    drops: number | null
    dropsPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface WrReceivingPreview {
  previewType: PreviewTypes.WR_RECEIVING_PREVIEW
  stats: {
    targets: number | null
    targetsPositionRank: number
    receptions: number | null
    receptionsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    drops: number | null
    dropsPositionRank: number
    yardsPerReception: number | null
    yardsPerReceptionPositionRank: number
    yardsAfterCatch: number | null
    yardsAfterCatchPositionRank: number
    yardsAfterCatchPerReception: number | null
    yardsAfterCatchPerReceptionPositionRank: number
    targetedQbRating: number | null // passer rating when targeted
    targetedQbRatingPositionRank: number
    avgDepthOfTarget: number | null
    avgDepthOfTargetPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface WrRushingPreview {
  previewType: PreviewTypes.WR_RUSHING_PREVIEW
  stats: {
    attempts: number | null // carries
    attemptsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    ypa: number | null
    ypaPositionRank: number
    avoidedTackles: number | null
    avoidedTacklesPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface TeReceivingPreview {
  previewType: PreviewTypes.TE_RECEIVING_PREVIEW
  stats: {
    targets: number | null
    targetsPositionRank: number
    receptions: number | null
    receptionsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    drops: number | null
    dropsPositionRank: number
    yardsPerReception: number | null
    yardsPerReceptionPositionRank: number
    yardsAfterCatch: number | null
    yardsAfterCatchPositionRank: number
    yardsAfterCatchPerReception: number | null
    yardsAfterCatchPerReceptionPositionRank: number
    targetedQbRating: number | null // passer rating when targeted
    targetedQbRatingPositionRank: number
    avgDepthOfTarget: number | null
    avgDepthOfTargetPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passRoute: number | null
    passRoutePositionRank: number
    passRouteGradeAverage: number
    positionRankTotal: number
  }
}

export interface TeRushingPreview {
  previewType: PreviewTypes.TE_RUSHING_PREVIEW
  stats: {
    attempts: number | null // carries
    attemptsPositionRank: number
    yards: number | null
    yardsPositionRank: number
    touchdowns: number | null
    touchdownsPositionRank: number
    ypa: number | null
    ypaPositionRank: number
    avoidedTackles: number | null
    avoidedTacklesPositionRank: number
    positionRankTotal: number
  }
  grades: null | {
    run: number | null
    runPositionRank: number
    runGradeAverage: number
    positionRankTotal: number
  }
}

export interface TBlockingPreview {
  previewType: PreviewTypes.T_BLOCKING_PREVIEW
  stats: {
    snapCountsOffense: number | null // offensive snaps played
    snapCountsOffensePositionRank: number
    snapCountsPassPlay: number | null // pass block snaps played
    snapCountsPassPlayPositionRank: number
    snapCountsRunBlock: number | null // run block snaps played
    snapCountsRunBlockPositionRank: number
    penalties: number | null
    penaltiesPositionRank: number
    sacksAllowed: number | null
    sacksAllowedPositionRank: number
    hitsAllowed: number | null
    hitsAllowedPositionRank: number
    pressuresAllowed: number | null
    pressuresAllowedPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passBlock: number | null
    passBlockPositionRank: number
    passBlockGradeAverage: number
    runBlock: number | null
    runBlockPositionRank: number
    runBlockGradeAverage: number
    positionRankTotal: number
  }
}

export interface TPositionalPivotPreview {
  previewType: PreviewTypes.T_POSITIONAL_PIVOT_PREVIEW
  stats: {
    snapCountsLt: number | null
    snapCountsLtPositionRank: number
    snapCountsLg: number | null
    snapCountsLgPositionRank: number
    snapCountsCe: number | null
    snapCountsCePositionRank: number
    snapCountsRg: number | null
    snapCountsRgPositionRank: number
    snapCountsRt: number | null
    snapCountsRtPositionRank: number
    snapCountsTe: number | null
    snapCountsTePositionRank: number
    positionRankTotal: number
  }
}

export interface GBlockingPreview {
  previewType: PreviewTypes.G_BLOCKING_PREVIEW
  stats: {
    snapCountsOffense: number | null // offensive snaps played
    snapCountsOffensePositionRank: number
    snapCountsPassPlay: number | null // pass block snaps played
    snapCountsPassPlayPositionRank: number
    snapCountsRunBlock: number | null // run block snaps played
    snapCountsRunBlockPositionRank: number
    penalties: number | null
    penaltiesPositionRank: number
    sacksAllowed: number | null
    sacksAllowedPositionRank: number
    hitsAllowed: number | null
    hitsAllowedPositionRank: number
    pressuresAllowed: number | null
    pressuresAllowedPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passBlock: number | null
    passBlockPositionRank: number
    passBlockGradeAverage: number
    runBlock: number | null
    runBlockPositionRank: number
    runBlockGradeAverage: number
    positionRankTotal: number
  }
}

export interface GPositionalPivotPreview {
  previewType: PreviewTypes.G_POSITIONAL_PIVOT_PREVIEW
  stats: {
    snapCountsLt: number | null
    snapCountsLtPositionRank: number
    snapCountsLg: number | null
    snapCountsLgPositionRank: number
    snapCountsCe: number | null
    snapCountsCePositionRank: number
    snapCountsRg: number | null
    snapCountsRgPositionRank: number
    snapCountsRt: number | null
    snapCountsRtPositionRank: number
    snapCountsTe: number | null
    snapCountsTePositionRank: number
    positionRankTotal: number
  }
}

export interface CBlockingPreview {
  previewType: PreviewTypes.C_BLOCKING_PREVIEW
  stats: {
    snapCountsOffense: number | null // offensive snaps played
    snapCountsOffensePositionRank: number
    snapCountsPassPlay: number | null // pass block snaps played
    snapCountsPassPlayPositionRank: number
    snapCountsRunBlock: number | null // run block snaps played
    snapCountsRunBlockPositionRank: number
    penalties: number | null
    penaltiesPositionRank: number
    sacksAllowed: number | null
    sacksAllowedPositionRank: number
    hitsAllowed: number | null
    hitsAllowedPositionRank: number
    pressuresAllowed: number | null
    pressuresAllowedPositionRank: number
    positionRankTotal: number
  }
  grades: {
    offense: number | null
    offensePositionRank: number
    offenseGradeAverage: number
    passBlock: number | null
    passBlockPositionRank: number
    passBlockGradeAverage: number
    runBlock: number | null
    runBlockPositionRank: number
    runBlockGradeAverage: number
    positionRankTotal: number
  }
}

export interface CPositionalPivotPreview {
  previewType: PreviewTypes.C_POSITIONAL_PIVOT_PREVIEW
  stats: {
    snapCountsLt: number | null
    snapCountsLtPositionRank: number
    snapCountsLg: number | null
    snapCountsLgPositionRank: number
    snapCountsCe: number | null
    snapCountsCePositionRank: number
    snapCountsRg: number | null
    snapCountsRgPositionRank: number
    snapCountsRt: number | null
    snapCountsRtPositionRank: number
    snapCountsTe: number | null
    snapCountsTePositionRank: number
    positionRankTotal: number
  }
}

export interface DiDefensePreview {
  previewType: PreviewTypes.DI_DEFENSE_PREVIEW
  stats: {
    tackles: number | null
    tacklesPositionRank: number
    assists: number | null
    assistsPositionRank: number
    forcedFumbles: number | null
    forcedFumblesPositionRank: number
    stops: number | null
    stopsPositionRank: number
    sacks: number | null
    sacksPositionRank: number
    hurries: number | null
    hurriesPositionRank: number
    hits: number | null
    hitsPositionRank: number
    totalPressures: number | null
    totalPressuresPositionRank: number
    battedPasses: number | null
    battedPassesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface DiSnapsPreview {
  previewType: PreviewTypes.DI_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number | null
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number | null
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number | null
    snapCountsPassRushPositionRank: number
    positionRankTotal: number
  }
}

export interface EdDefensePreview {
  previewType: PreviewTypes.ED_DEFENSE_PREVIEW
  stats: {
    tackles: number | null
    tacklesPositionRank: number
    assists: number | null
    assistsPositionRank: number
    forcedFumbles: number | null
    forcedFumblesPositionRank: number
    stops: number | null
    stopsPositionRank: number
    sacks: number | null
    sacksPositionRank: number
    hurries: number | null
    hurriesPositionRank: number
    hits: number | null
    hitsPositionRank: number
    totalPressures: number | null
    totalPressuresPositionRank: number
    battedPasses: number | null
    battedPassesPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface EdSnapsPreview {
  previewType: PreviewTypes.ED_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number | null
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number | null
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number | null
    snapCountsPassRushPositionRank: number
    positionRankTotal: number
  }
}

export interface LbDefensePreview {
  previewType: PreviewTypes.LB_DEFENSE_PREVIEW
  stats: {
    tackles: number | null
    tacklesPositionRank: number
    assists: number | null
    assistsPositionRank: number
    forcedFumbles: number | null
    forcedFumblesPositionRank: number
    stops: number | null
    stopsPositionRank: number
    sacks: number | null
    sacksPositionRank: number
    missedTackles: number | null
    missedTacklesPositionRank: number
    qbRatingAgainst: number | null // passer rating allowed
    qbRatingAgainstPositionRank: number
    totalPressures: number | null
    totalPressuresPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    coverageDefense: number | null
    coverageDefensePositionRank: number
    coverageDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface LbSnapsPreview {
  previewType: PreviewTypes.LB_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number | null // total snaps
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number | null
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number | null
    snapCountsPassRushPositionRank: number
    snapCountsCoverage: number | null
    snapCountsCoveragePositionRank: number
    positionRankTotal: number
  }
}

export interface CbDefensePreview {
  previewType: PreviewTypes.CB_DEFENSE_PREVIEW
  stats: {
    tackles: number | null
    tacklesPositionRank: number
    assists: number | null
    assistsPositionRank: number
    forcedFumbles: number | null
    forcedFumblesPositionRank: number
    stops: number | null
    stopsPositionRank: number
    sacks: number | null
    sacksPositionRank: number
    missedTackles: number | null
    missedTacklesPositionRank: number
    interceptions: number | null
    interceptionsPositionRank: number
    qbRatingAgainst: number | null // passer rating allowed
    qbRatingAgainstPositionRank: number
    yardsPerReception: number | null
    yardsPerReceptionPositionRank: number
    totalPressures: number | null
    totalPressuresPositionRank: number
    passBreakUps: number | null // PBUs
    passBreakUpsPositionRank: number
    receptions: number | null // receptions allowed
    receptionsPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    coverageDefense: number | null
    coverageDefensePositionRank: number
    coverageDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface CbSnapsPreview {
  previewType: PreviewTypes.CB_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number | null // total snaps
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number | null
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number | null
    snapCountsPassRushPositionRank: number
    snapCountsCoverage: number | null
    snapCountsCoveragePositionRank: number
    positionRankTotal: number
  }
}

export interface SDefensePreview {
  previewType: PreviewTypes.S_DEFENSE_PREVIEW
  stats: {
    tackles: number | null
    tacklesPositionRank: number
    assists: number | null
    assistsPositionRank: number
    forcedFumbles: number | null
    forcedFumblesPositionRank: number
    stops: number | null
    stopsPositionRank: number
    sacks: number | null
    sacksPositionRank: number
    missedTackles: number | null
    missedTacklesPositionRank: number
    interceptions: number | null
    interceptionsPositionRank: number
    qbRatingAgainst: number | null // passer rating allowed
    qbRatingAgainstPositionRank: number
    yardsPerReception: number | null
    yardsPerReceptionPositionRank: number
    totalPressures: number | null
    totalPressuresPositionRank: number
    passBreakUps: number | null // PBUs
    passBreakUpsPositionRank: number
    receptions: number | null // receptions allowed
    receptionsPositionRank: number
    positionRankTotal: number
  }
  grades: {
    defense: number | null
    defensePositionRank: number
    defenseGradeAverage: number
    passRushDefense: number | null
    passRushDefensePositionRank: number
    passRushDefenseGradeAverage: number
    runDefense: number | null
    runDefensePositionRank: number
    runDefenseGradeAverage: number
    coverageDefense: number | null
    coverageDefensePositionRank: number
    coverageDefenseGradeAverage: number
    positionRankTotal: number
  }
}

export interface SSnapsPreview {
  previewType: PreviewTypes.S_SNAPS_PREVIEW
  stats: {
    snapCountsDefense: number | null // total snaps
    snapCountsDefensePositionRank: number
    snapCountsRunDefense: number | null
    snapCountsRunDefensePositionRank: number
    snapCountsPassRush: number | null
    snapCountsPassRushPositionRank: number
    snapCountsCoverage: number | null
    snapCountsCoveragePositionRank: number
    positionRankTotal: number
  }
}

export interface KFieldGoalsPreview {
  previewType: PreviewTypes.K_FIELD_GOALS_PREVIEW
  stats: {
    totalAttempts: number | null
    totalAttemptsPositionRank: number
    totalMade: number | null
    totalMadePositionRank: number
    fiftyAttempts: number | null
    fiftyAttemptsPositionRank: number
    fiftyMade: number | null
    fiftyMadePositionRank: number
    patAttempts: number | null
    patAttemptsPositionRank: number
    patMade: number | null
    patMadePositionRank: number
    positionRankTotal: number
    totalPercent: number | null
    totalPercentPositionRank: number
  }
  grades: {
    fgepKicker: number | null
    fgepKickerPositionRank: number
    fgepKickerGradeAverage: number
    positionRankTotal: number
  }
}

export interface KKickingPreview {
  previewType: PreviewTypes.K_KICKING_PREVIEW
  stats: {
    kicksReturned: number | null
    kicksReturnedPositionRank: number
    percentReturned: number | null // kickoff return %
    percentReturnedPositionRank: number
    averageYardsPerReturn: number | null // yards per return
    averageYardsPerReturnPositionRank: number
    averageStartingFieldPosition: number | null // average field position
    averageStartingFieldPositionPositionRank: number
    positionRankTotal: number
  }
  grades: {
    kickoffKicker: number | null
    kickoffKickerPositionRank: number
    kickoffKickerGradeAverage: number
    positionRankTotal: number
  }
}

export interface PPuntingPreview {
  previewType: PreviewTypes.P_PUNTING_PREVIEW
  stats: {
    attempts: number | null // punts
    attemptsPositionRank: number
    averageNetYards: number | null // punt gross avg
    averageNetYardsPositionRank: number
    long: number | null
    longPositionRank: number
    insideTwenties: number | null // pinned inside the 20
    insideTwentiesPositionRank: number
    percentReturned: number | null // % return
    percentReturnedPositionRank: number
    averageYardsPerReturn: number | null // yards per return
    averageYardsPerReturnPositionRank: number
    touchbacks: number | null
    touchbacksPositionRank: number
    downeds: number | null // downed
    downedsPositionRank: number
    outOfBounds: number | null
    outOfBoundsPositionRank: number
    fairCatches: number | null
    fairCatchesPositionRank: number
    averageHangtime: number | null
    averageHangtimePositionRank: number
    positionRankTotal: number
  }
  grades: {
    punter: number | null
    punterPositionRank: number
    punterGradeAverage: number
    positionRankTotal: number
  }
}

export interface PKickingPreview {
  previewType: PreviewTypes.P_KICKING_PREVIEW
  stats: {
    kicksReturned: number | null
    kicksReturnedPositionRank: number
    percentReturned: number | null // kickoff return %
    percentReturnedPositionRank: number
    averageYardsPerReturn: number | null // yards per return
    averageYardsPerReturnPositionRank: number
    averageStartingFieldPosition: number | null // average field position
    averageStartingFieldPositionPositionRank: number
    positionRankTotal: number
  }
  grades: {
    kickoffKicker: number | null
    kickoffKickerPositionRank: number
    kickoffKickerGradeAverage: number
    positionRankTotal: number
  }
}

type PrimaryStatsPreview =
  | QbPassingPreview
  | RbRushingPreview
  | FbRushingPreview
  | WrReceivingPreview
  | TeReceivingPreview
  | TBlockingPreview
  | GBlockingPreview
  | CBlockingPreview
  | DiDefensePreview
  | EdDefensePreview
  | LbDefensePreview
  | CbDefensePreview
  | SDefensePreview
  | KFieldGoalsPreview
  | PPuntingPreview
  | null // Not enough snaps

type SecondaryStatsPreview =
  | QbRushingPreview
  | RbReceivingPreview
  | FbReceivingPreview
  | WrRushingPreview
  | TeRushingPreview
  | TPositionalPivotPreview
  | GPositionalPivotPreview
  | CPositionalPivotPreview
  | DiSnapsPreview
  | EdSnapsPreview
  | LbSnapsPreview
  | CbSnapsPreview
  | SSnapsPreview
  | KKickingPreview
  | PKickingPreview
  | null // Not enough snaps

export interface PremiumStatsPreviewKeyStatsResponse {
  primaryStats: PrimaryStatsPreview
  primaryUrl: string
  secondaryStats: SecondaryStatsPreview
  secondaryUrl: string
}
