import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React, { useEffect, StrictMode } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { debounce } from 'lodash'
import { generateWebUiGlobalStyles } from '@pff-consumer/web-ui/styles/globals'
import { WebUiProvider } from '@pff-consumer/web-ui/components/web-ui-provider'
import { nextJsDataProvider, checkoutHooks } from '@pff-consumer/data-provider/checkout'
import { createUseStyles } from '@pff-consumer/web-ui/styles'
import { withFeatureFlagProvider } from '@pff-consumer/feature-flags'
import { useAnalytics } from '@pff-consumer/analytics'
import { usePathname } from 'next/navigation'
import './styles.css'

const useGlobalStyles = createUseStyles({
  ...generateWebUiGlobalStyles({ fontUrlPath: '/assets/fonts' }),
})

const GlobalStyles = () => {
  useGlobalStyles()

  return null
}
const { useCheckoutActions, useEmail } = checkoutHooks

const CheckoutApp = ({ Component, pageProps }: AppProps) => {
  const { initializeAnalytics } = useAnalytics()
  const { updateWindowDimensions } = useCheckoutActions()
  const pathName = usePathname()
  const email = useEmail()

  useEffect(() => {
    initializeAnalytics()
  }, [])

  useEffect(() => {
    if (email) {
      // @ts-expect-error klaviyo variable will be injected by the klaviyo script - https://developers.klaviyo.com/en/docs/introduction_to_the_klaviyo_object#what-is-the-klaviyo-object
      klaviyo.push([
        'identify',
        {
          email,
        },
      ])
      // @ts-expect-error klaviyo variable will be injected by the klaviyo script - https://developers.klaviyo.com/en/docs/introduction_to_the_klaviyo_object#what-is-the-klaviyo-object
      klaviyo.track('Viewed Page', {
        url: window.location.href.split('?')[0],
      })
    }
  }, [email, pathName])

  useEffect(() => {
    const updateDevice = () => {
      updateWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }
    updateDevice()
    window.addEventListener('resize', debounce(updateDevice, 100))
    return () => {
      window.removeEventListener('resize', updateDevice)
    }
  }, [updateWindowDimensions])

  return (
    <StrictMode>
      <WebUiProvider isBrowserTheme>
        <GlobalStyles />
        <Head>
          <title>Subscribe | PFF+</title>

          <meta
            name='viewport'
            content='width=device-width, user-scalable=no'
          />
        </Head>
        <main className='app'>
          <Component {...pageProps} />
        </main>
      </WebUiProvider>
    </StrictMode>
  )
}

export default withFeatureFlagProvider()(nextJsDataProvider(CheckoutApp))
