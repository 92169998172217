import type { PlayerLogoTeamDetails } from '@pff-consumer/schema'
import { getTeamSlugByFranchiseId } from './getTeamSlugByFranchiseId'
import { getTeamIconSlugFromAbbreviation } from './getTeamIconSlugFromAbbreviation'
import { SVGGroup } from '../svg/svg-group'

export const getTeamIconAndGroup = (team: PlayerLogoTeamDetails) => {
  let svgGroup = ''
  let iconName = ''

  // If Icon is not available in current map, could be old team abbreviation example OAK for Oakland Raiders
  // Look for it in getTeamSlugByFranchiseId
  if (team.league === 'NFL') {
    iconName = getTeamIconSlugFromAbbreviation(team.teamAbbreviation) || getTeamSlugByFranchiseId(team.teamId)
    svgGroup = SVGGroup.NFL
  }

  if (team.league === 'NCAA') {
    iconName = getTeamSlugByFranchiseId(team.teamId)
    svgGroup = SVGGroup.NCAA
  }

  // If icon is unavailable in getTeamIconSlugFromAbbreviation and getTeamSlugByFranchiseId
  // Display PFF logo
  if (!iconName) {
    iconName = 'pff-logo-dark'
    svgGroup = SVGGroup.Icons
  }

  return {
    iconName,
    svgGroup,
  }
}
