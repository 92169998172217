import { DRAFT_BREAKPOINT_WIDTH } from '@pff-consumer/utils'
import { useCallback } from 'react'
import { AnalyticsEventArgs } from '../shared/analytics-types'
import { useAnalytics } from '../web/index.web'

const MOCK_GROOT_ID = 'mock-groot-id'

declare global {
  interface Window {
    sessionData?: {
      grootUid?: string
      isPremiumSubscriber?: boolean
      showAds?: boolean
    }
  }
}

let uid: string | undefined

if (process.env.NODE_ENV === 'development') {
  uid = MOCK_GROOT_ID
} else if (typeof window !== 'undefined') {
  uid = window?.sessionData?.grootUid
}

const { LOWEST_WIDTH_SUPPORTED, SMALL_SCREEN_MEDIAN_WIDTH, LARGE_SCREEN_MIN_WIDTH } = DRAFT_BREAKPOINT_WIDTH

const getDevice = (width: number) => {
  if (width < SMALL_SCREEN_MEDIAN_WIDTH) {
    return 'Mobile Web'
  }
  if (width < LARGE_SCREEN_MIN_WIDTH) {
    return 'Tablet'
  }
  return 'Desktop'
}

export const useFantasyToolsAnalyticsEvent = () => {
  const width = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : LOWEST_WIDTH_SUPPORTED

  const { sendAnalyticsEvent } = useAnalytics()
  const skipNextJsAuth = true

  const sendFantasyToolsAnalyticsEvent = useCallback(
    (...args: AnalyticsEventArgs) => {
      const [eventName, eventProperties = {}] = args
      const device = getDevice(width)
      let tool
      if (window?.location?.href?.includes('mock-draft-simulator')) {
        tool = 'mock_draft_simulator'
      } else if (window?.location?.href?.includes('live-draft-assistant')) {
        tool = 'live_draft_assistant'
      } else if (window?.location?.href?.includes('rankings')) {
        tool = 'rankings'
      }
      const allEventProperties = { ...eventProperties, device, pffUserId: uid, tool, skipNextJsAuth }

      const analyticsArgs = [eventName, allEventProperties] as AnalyticsEventArgs
      sendAnalyticsEvent(...analyticsArgs)
    },
    [width, sendAnalyticsEvent, skipNextJsAuth]
  )

  return { sendFantasyToolsAnalyticsEvent }
}
